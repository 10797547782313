import React from 'react';
import { getIdFromSlug } from 'lib/url';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { usePage } from 'hooks/page';

import { addCartItem, clearCart } from 'services/cart';
import { useGetStrollerQuery, useGetStrollerAccessoriesQuery } from 'services/strollers';
import { useGetLowestPrice } from 'hooks/product';
import { appInsights } from 'lib/trackers';

import AccessoriesLoader from './Partials/AccessoriesLoader';
import SingleLoader from './Partials/SingleLoader';
import NewSubscriptionForm from 'containers/Forms/NewSubscription';

// Import page modules
import ProductTitle from './_modules/ProductTitle';
import ProductMobileTitle from './_modules/ProductMobileTitle';
import ProductGallery from './_modules/ProductGallery';
import ProductDescription from './_modules/ProductDescription';
import ProductSpecifications from './_modules/ProductSpecifications';

const SingleStroller = () => {
	const { slug } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const { data: stroller, isLoading: loadingStroller } = useGetStrollerQuery(getIdFromSlug(slug));
	const { data: accessories, isLoading: loadingAccessories } = useGetStrollerAccessoriesQuery(getIdFromSlug(slug));
	const price = useGetLowestPrice(stroller);
	let image = null;

	const [registerPosition, SEO] = usePage('SingleStroller', {
		stroller: stroller,
		accessories: accessories,
		price: price,
	}, [
		ProductTitle,
		ProductGallery,
		ProductMobileTitle,
		ProductDescription,
		ProductSpecifications,
	]);

	const onSubscriptionSubmit = (values) => {
		appInsights.trackEvent({
			name: 'AddToCart',
			properties: {
				strollerId: stroller.strollerId,
				strollerName: stroller.name,
			},
		});

		return new Promise((resolve) => {
			dispatch(clearCart());
			dispatch(addCartItem(values));
			history.push('/kassan');
			resolve(1);
		});
	}

	if (loadingStroller) {
		return (
			<SingleLoader registerPosition={registerPosition} />
		);
	}

	if (stroller.images != null && stroller.images.length > 0) {
		if (stroller.images[0].sizes != null) {
			let small = stroller.images[0].sizes.find(size => size.size == 'sm');
			if (small != null) {
				image = small;
			}
		}
	};

	return (
		<div className="flex flex-wrap mt-spacing">
			<SEO>
				<title>{stroller.name}</title>
				<meta name="description" content={stroller.description} />
				<meta property="og:type" content="article" />
				<meta property="og:title" content={`${stroller.name} | Stroller`} />
				<meta property="og:description" content={stroller.description} />
				<meta property="og:url" content={window.location.href} />
				<meta property="article:publisher" content="https://www.facebook.com/strollyse" />
				<meta property="og:image" content={image.src} />
				<meta property="og:image:width" content="400" />
				<meta property="og:image:height" content="400" />
				<meta property="og:image:type" content="image/jpeg" />
			</SEO>

			<div className="w-full md:w-1/2 md:pr-10">
				{registerPosition('before_description')}

				<div
					className="my-12 text-xl"
					dangerouslySetInnerHTML={{ __html: stroller.description }}
				/>

				{registerPosition('after_description')}
			</div>

			<div className="w-full md:w-1/2 md:pl-10 mt-10 md:mt-0">
				{registerPosition('before_form')}

				{!loadingAccessories ?
					<NewSubscriptionForm
						stroller={stroller}
						accessories={accessories}
						onSubmit={onSubscriptionSubmit}
					/>
				: <AccessoriesLoader />}

				{registerPosition('after_form')}
			</div>

		</div>
	);
}

export default SingleStroller;